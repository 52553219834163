<template>
  <div class="fluid" id="machineList">
    <div>
      <div class="mms-list-dev">
        <v-card elevation="0" class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h4 class="indigo--text" style="font-weight:bold;margin:auto;">
                MASTER MACHINE
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="mms-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0; padding-top:15px;"
                >
                  <div class="d-flex align-content-start">
                    <div
                      style="margin-right:15px; height:40px;"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        style="
                      font-size: 12px;
                      "
                        @click="addMachine"
                        elevation="2"
                        tilerounded="5px"
                        color="primary"
                        class="white--text"
                        small
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Add Machine</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                    <div style="width: 210px;">
                      <v-autocomplete
                        label="Company"
                        v-model="paramAPI.company_id"
                        :items="dropdown.company"
                        item-text="name"
                        item-value="id"
                        return-id
                        dense
                        outlined
                        clearable
                        @change="companyWatcher"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div style="width: 50%; margin-left:auto">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      @keyup.enter="searchEnter"
                      label="Search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="font-size:14px; padding: 0; display: flex; flex-direction: row;"
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="font-size:14px; padding: 0; display: flex; flex-direction: row; color:green;"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.code`]="{ item }">
                  <div
                    style="font-size:14px; padding: 0; display: flex; flex-direction: row;"
                  >
                    {{ item.code }}
                  </div>
                </template>
                <template v-slot:[`item.speed_per_hour`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.speed_per_hour }}
                  </div>
                </template>
                <template v-slot:[`item.speed_per_second`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.speed_per_second }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    color="primary"
                    class="mr-2"
                    @click.stop="rowClick(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="error" @click.stop="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog
      v-if="form !== null"
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="editDialog"
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            form.name !== null ? form.name.toUpperCase() : 'Add New Machine'
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="enableEdit">
              {{ !isEdit ? 'Edit' : 'Cancel' }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form
            :disabled="!isEdit"
            ref="entryForm1"
            @submit.prevent="saveMasterMachine"
            style="position: relative;"
          >
            <v-row style="padding-top:15px;">
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Machine Name
                </p>
                <v-text-field
                  dense
                  v-model="form.name"
                  :outlined="isEdit"
                  style="height:45px;"
                  :rules="rules.nameRules"
                />
              </v-col>
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Code
                </p>
                <v-text-field
                  dense
                  v-model="form.code"
                  :outlined="isEdit"
                  style="height:45px;"
                  :rules="rules.codeRules"
                />
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Company
                </p>
                <v-autocomplete
                  v-model="form.company"
                  :items="dropdown.company"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  :outlined="isEdit"
                  :clearable="isEdit"
                  style="height:45px;"
                  :rules="rules.companyRules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="isEdit">
                <v-btn
                  depressed
                  color="primary"
                  style="width:100%;"
                  type="submit"
                >
                  Save change
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>

          <v-card v-if="form.act === 'update'">
            <v-card-title>
              Standard Value <v-spacer></v-spacer>
              <v-btn
                style="
                      font-size: 10px;
                      "
                @click="addProperty"
                elevation="2"
                tilerounded="5px"
                x-small
                color="primary"
                class="white--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small class="">
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>Add Property</span>
                </v-tooltip>
              </v-btn>
            </v-card-title>
            <v-data-table
              v-if="detail !== null"
              :headers="detailHeaders"
              :items="detail.machine_standard_value"
              :items-per-page="5"
              mobile-breakpoint="0"
              fixed-header
              hide-default-footer
            >
              <template v-slot:[`item.machine_property`]="{ item }">
                <div
                  style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                >
                  {{ item.machine_property.name }}
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon
                  small
                  color="primary"
                  class="mr-2"
                  @click.stop="editStandard(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small color="error" @click.stop="deleteStandard(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="propertyDialog" persistent max-width="400px">
      <v-card v-if="sForm !== null">
        <v-card-title>
          <span class="text-h5">Add/Edit Property</span>
        </v-card-title>
        <v-form ref="entryForm2" @submit.prevent="saveProperty">
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col
                  cols="12"
                  class="d-flex flex-column"
                  v-if="this.sForm.act === 'add'"
                >
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Machine
                  </p>
                  <v-autocomplete
                    v-model="sForm.machine_id"
                    :items="dropdown.machine"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearabl
                    style="height:45px;"
                    :rules="rules.machineRules"
                  ></v-autocomplete>
                </v-col> -->
                <v-col
                  cols="12"
                  class="d-flex flex-column"
                  v-if="this.sForm.act === 'add'"
                >
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Property
                  </p>
                  <v-autocomplete
                    v-model="sForm.property_id"
                    :items="dropdown.property"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearabl
                    style="height:45px;"
                    :rules="rules.propertyRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Min Value
                  </p>
                  <v-text-field
                    v-model="sForm.min_value"
                    :rules="rules.minRules"
                    dense
                    outlined
                    style="height:45px;"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Max Value
                  </p>
                  <v-text-field
                    v-model="sForm.max_value"
                    :rules="rules.maxRules"
                    dense
                    outlined
                    style="height:45px;"
                  />
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Unit
                  </p>
                  <v-autocomplete
                    v-model="sForm.unit"
                    :items="dropdown.unit"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    outlined
                    clearabl
                    style="height:45px;"
                    :rules="rules.unitRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeProperty">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text type="submit">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /* mapActions */ } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'mmsmastermachine',
  data: () => ({
    editDialog: false,
    propertyDialog: false,
    proc: buildType.apiURL('proc'),
    mms: buildType.apiURL('mms'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null
    },
    totalData: 0,
    form: null,
    sForm: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Code',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Speed / H',
        value: 'speed_per_hour',
        align: 'left',
        sortable: false
      },
      {
        text: 'Speed / S',
        value: 'speed_per_second',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    detailHeaders: [
      {
        text: 'Property',
        value: 'machine_property',
        align: 'left',
        sortable: false
      },
      {
        text: 'Min Value',
        value: 'min_value',
        align: 'left',
        sortable: false
      },
      {
        text: 'Max Value',
        value: 'max_value',
        align: 'left',
        sortable: false
      },
      {
        text: 'Unit',
        value: 'unit_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    dropdown: {
      company: [],
      machine: [],
      property: [],
      unit: []
    },
    rules: {
      nameRules: [],
      codeRules: [],
      companyRules: [],
      machineRules: [],
      propertyRules: [],
      unitRules: [],
      minRules: [],
      maxRules: []
    },
    tabs: [],
    instance: null
  }),

  async mounted() {
    this.instance = axios.create({
      headers: {
        'x-method': 'conventional',
        'x-token':
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1pc0BzYW5xdWEuY28uaWQiLCJpZCI6IjEyOCIsImRldmljZSI6Im1vYmlsZSIsInV1aWQiOiJjY2ZiMmI2OS1jYjBjLTQwYzQtOGE1Mi03MjI5NzUzZTQ1M2IiLCJzY29wZSI6ImFjY2Vzc190b2tlbiIsImlhdCI6MTcwNDc4MTQ3NCwiZXhwIjoxNzA1Mzg2Mjc0fQ.YXG5BQM0x7LrugPCdfLHSprZlwUib3XBiGucS3ri0kBVBI9Ua-ebcZd-C_ROZ0kkipnWxjimpafReZNJzrT7Bg5n6a93r62Bujr-UNakXUAZqERh8vK0uMJrkn6OJ1AeJm9hewatlZWLTs1t4-LqxPNczyhserQMRYd4b_uZVIxrVonJXjgudVLGQDxOcYf5iuxhKs-uQBEg3irj92hybZtQS8qBUrChPWjEx2bMYyeiyRZDxzCJOMl0133jWLMcj8voApywLmn4EG_0S4Ei1LCaM2K2G1uKcegJA8MhInexu2CgcfwIb-Lce_F-eRMc69N5ZybqgmnsuYH6sjQIKg',
        'x-device': 'web'
      }
    })

    this.setArrTabs()
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    await this.getDataFromApi()
    await this.initDropdown()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setTabs']),
    setArrTabs() {
      const tabsArr = [
        {
          tab_name: 'MMS',
          path: '/mms'
        },
        {
          tab_name: 'MASTER DATA',
          // path: '/e-catalogue/fpb-list'
          path: '',
          sub_tabs: [
            {
              sub_title: 'Master Machine',
              path: '/mms/mastermachine'
            },
            {
              sub_title: 'Master Property',
              path: '/mms/masterproperty'
            },
            {
              sub_title: 'Master Category',
              path: '/mms/mastercategory'
            },
            {
              sub_title: 'Master Reason',
              path: '/mms/masterreasone'
            }
          ],
          isShown: false
        }
      ]

      this.tabs = tabsArr
      this.setTabs(tabsArr)
    },
    // ...mapActions(['dropdownPlant']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    companyWatcher() {
      this.getDataFromApi()
    },
    async initDropdown() {
      this.dropdownCompany()
      this.dropdownMachine()
      this.dropdownProperty()
      this.dropdownUnit()
    },
    searchEnter() {
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let url = `${this.mms}master/universal/machine/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`
      if (this.paramAPI.company_id !== null) {
        url =
          url +
          `&filter=[{"company_id":${
            this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
          }}]`
      }
      return await new Promise(resolve => {
        this.instance
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    enableEdit() {
      var oldForm = this.form
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
        this.form = oldForm
        if (this.form.act === 'add') {
          this.closeDialog()
        }
      }
    },
    addMachine() {
      this.form = {
        act: 'add',
        name: null,
        code: null,
        company: null
      }
      this.detail = null
      this.isEdit = true
      setTimeout(() => {
        this.editDialog = true
      }, 300)
    },
    rowClick(pItem) {
      console.log(pItem)
      this.form = {
        act: 'update',
        id: pItem.id,
        name: pItem.name,
        code: pItem.code,
        company: {
          id: pItem.company_id,
          name: pItem.company_name
        }
      }
      this.getDetail(pItem.id)
    },
    async getDetail(val) {
      const url = `${this.mms}machine/detail?id=${val}`
      await this.instance
        .get(url)
        .then(res => {
          console.log(res.data)
          if (res.data.status_code === '99') {
            this.showMsgDialog('warning', res.data.status_msg, false)
            return
          }
          this.detail = res.data.data
          if (!this.editDialog) {
            setTimeout(() => {
              this.editDialog = true
            }, 300)
          }
        })
        .catch(err => {
          this.showMsgDialog('error', err, false)
        })
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          await this.instance
            .delete(`${this.mms}master/universal/machine/delete/${pItem.id}`)
            .then(async res => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch(err => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    saveMasterMachine() {
      this.rules.nameRules = [v => !!v || 'Name is required']
      this.rules.codeRules = [v => !!v || 'Code is required']
      this.rules.companyRules = [v => !!v || 'Company is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm1.validate()) {
          // self.createNewForm()
          const form = {
            act: self.form.act,
            name: self.form.name,
            code: self.form.code,
            company_id: self.form.company.id,
            company_name: self.form.company.name
          }
          if (self.form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }

          self.saveMachine(form)
        }
      })
    },
    async saveMachine(form) {
      await this.instance
        .post(`${this.mms}master/universal/machine/save`, form)
        .then(async res => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.isEdit = false
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            if (this.form.act === 'add') {
              this.form.act = 'update'
              this.form.id = res.data.created_id
              this.getDetail(res.data.created_id)
            }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeDialog() {
      this.form = null
      this.detail = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    closeProperty() {
      this.sForm = null
      setTimeout(() => {
        this.propertyDialog = false
      }, 300)
    },
    addProperty() {
      this.sForm = {
        act: 'add',
        property_id: null,
        machine_id: null,
        min_value: null,
        max_value: null,
        unit: null
      }
      // console.log(this.detail)
      setTimeout(() => {
        this.propertyDialog = !this.propertyDialog
      }, 300)
    },
    editStandard(pItem) {
      this.sForm = {
        act: 'update',
        id: pItem.id,
        min_value: pItem.min_value,
        max_value: pItem.max_value,
        unit: {
          id: pItem.unit_id,
          name: pItem.unit_name
        }
      }
      setTimeout(() => {
        this.propertyDialog = !this.propertyDialog
      }, 300)
    },
    async deleteStandard(pItem) {
      await this.instance
        .delete(`${this.mms}machine_std_val/delete/${pItem.id}`)
        .then(async res => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail(this.form.id)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    saveProperty() {
      this.rules.minRules = [v => !!v || 'Min is required']
      this.rules.maxRules = [v => !!v || 'Max is required']
      if (this.sForm.act === 'add') {
        this.rules.machineRules = [v => !!v || 'Machine is required']
        this.rules.propertyRules = [v => !!v || 'Property is required']
      }
      this.rules.unitRules = [v => !!v || 'Unit is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm2.validate()) {
          // self.createNewForm()
          const form = {
            act: self.sForm.act,
            min_value: self.sForm.min_value,
            max_value: self.sForm.max_value,
            unit_id: self.sForm.unit.id,
            unit_name: self.sForm.unit.name
          }
          if (form.act === 'add') {
            Object.assign(form, {
              property_id: self.sForm.property_id,
              machine_id: self.detail.id
              // machine_id: self.sForm.machine_id
            })
          }
          if (form.act === 'update') {
            Object.assign(form, { id: self.sForm.id })
          }
          self.submitProperty(form)
        }
      })
    },
    async submitProperty(form) {
      await this.instance
        .post(`${this.mms}machine_std_val/${form.act}`, form)
        .then(async res => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail(this.form.id)
            this.closeProperty()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async dropdownCompany() {
      const eSanqua = 'https://api.sanquawater.co.id/dev-esanqua/'
      console.log('PROC API >>>>>>>>')
      await this.instance
        // .get(`${context.state.hr}master/company/list`)
        .get(`${eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.company = res.data.data)
          }
          return (this.dropdown.company = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownMachine() {
      await this.instance
        .get(`${this.mms}master/universal/machine/dropdown`)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.machine = res.data.data)
          }
          return (this.dropdown.machine = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.machine = [])
        })
    },
    async dropdownProperty() {
      await this.instance
        .get(`${this.mms}master/universal/machineproperty/dropdown`)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.property = res.data.data)
          }
          return (this.dropdown.property = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.property = [])
        })
    },
    async dropdownUnit() {
      const proc = 'https://api.sanquawater.co.id/dev-eprocurement/'
      await this.instance
        .get(`${proc}master/unit/drop_down?keyword=`)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.unit = res.data.data)
          }
          return (this.dropdown.unit = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.unit = [])
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#machineList {
  position: relative;
  .mms-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .mms-list-toolbar-1 {
      .mms-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #machineList {
    .mms-list-dev {
      .mms-list-toolbar-1 {
        .mms-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
